<template>
  <div v-if="rendersMessage" :class="formMessageClass" @click="close">
    <div v-if="isMessageList">
      <div v-for="(msgLine, index) in messageList" :key="index">
        {{ msgLine }}
      </div>
    </div>
    <div v-else>
      {{ message }}
    </div>
  </div>
</template>

<script>
import {
  MessageTypeEnum,
  MESSAGE_COMPONENT,
  EMPTY_STRING,
  EventType,
} from '@/constants'
import { isString, isArray } from '@/helpers'

const CSN_MESSAGE_CLASS = 'csn-message'
const CSN_MESSAGE_BOTTOM_CLASS = 'csn-message-bottom'
const CSN_MESSAGE_TOP_CLASS = 'csn-message-top'
const BR = '<br>'

export default {
  name: MESSAGE_COMPONENT,
  props: {
    type: {
      type: String,
      default: MessageTypeEnum.SUCCESS,
      validator: (value) =>
        [
          MessageTypeEnum.SUCCESS,
          MessageTypeEnum.FAILURE,
          MessageTypeEnum.NOTICE,
        ].includes(value),
    },
    message: {
      type: [Array, String],
      default: () => [],
    },
    hasBottom: {
      type: Boolean,
      default: false,
    },
    hasTop: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    rendersMessage() {
      return this.message && this.message.length
    },
    formMessageClass() {
      return [
        CSN_MESSAGE_CLASS,
        `${CSN_MESSAGE_CLASS}-${this.type}`,
        this.hasBottom ? CSN_MESSAGE_BOTTOM_CLASS : EMPTY_STRING,
        this.hasTop ? CSN_MESSAGE_TOP_CLASS : EMPTY_STRING,
      ]
    },
    isMessageArray() {
      return isArray(this.message)
    },
    isMessageList() {
      return (
        this.isMessageArray ||
        (isString(this.message) && this.message.includes(BR))
      )
    },
    messageList() {
      return this.isMessageArray ? this.message : this.message.split(BR)
    },
  },
  methods: {
    close() {
      this.$emit(EventType.CLOSE)
    },
  },
}
</script>
